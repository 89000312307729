import { Link } from "react-router-dom";

import "./CardLink.scss";

const CardLink = ({
  image,
  title,
  alt,
  link,
  description,
  clickFunction,
}: {
  image?: string;
  title?: string;
  description?: string;
  alt?: string;
  link?: string;
  clickFunction?: Function;
}) => (
  <Link to={`/${link}`} className="card-link">
    <div className="card-content black-background h-100 shadow pt-3 d-flex flex-column justify-content-between rounded20">
      <h3 className="px-2 pb-3 pt-1">{title}</h3>
      <img
        src={`../../assets/card/${image}`}
        alt={alt ? alt : title}
        className="img-fluid image-card"
        aria-label={description}
      />
    </div>
  </Link>
);

export default CardLink;
