import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import BanniereHeader from "../../components/banniereHeader/BanniereHeader";
import DstButton from "../../components/DstButton/DstButton";
import PictoContent from "../../components/Pictocontent/PictoContent";
import { Helmet } from "react-helmet";
import "./ReproductionCle.scss";

const ReproductionCle = () => {
  const navigate = useNavigate();
  return (
    <div>
      <React.Fragment>
        <Helmet>
          <title>Clés Badges Paris 17 - Serrurerie Guy Môquet</title>
          <meta
            name="description"
            content="Besoin de faire reproduire une clé à Paris ? Serrurerie Guy Môquet offre un service rapide et fiable de reproduction de clés à Paris et ses environs. Contactez-nous dès maintenant pour une reproduction de clé de haute qualité."
          />
          <meta
            name="keywords"
            content="reproduction clé Paris, copie clé Paris, clé non reproductible, serrurier clé Paris, Bagde Paris"
          />
        </Helmet>
        <section>
          <BanniereHeader
            title="Reproduction de clé - badge"
            subTitle={
              <h2 className="h4">Spécialiste dans la métallerie-serrurerie</h2>
            }
            backgroundImage="background-depannage.webp"
          />
        </section>
        <section className="container my-5">
          <div className="container-fluid">
            <p className="fw-bold">
              En cas de vol ou de perte de ses clés d’origine la reproduction
              d’une clé permet d’ouvrir sa porte dans devoir changer le cylindre
              ou d’avoir un double de sa clé. Il existe plusieurs niveaux de
              sécurité en serurie les clés non-protégés ou copiables et les clés
              protégées.
            </p>
            <Row className="mt-md-5 mt-4">
              <Col
                sm
                className="me-sm-3"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <PictoContent
                  image="../../assets/icons/icon-cles.svg"
                  title="Les clés non-protégées ou clés copiables :"
                  description={
                    <p>
                      Elle sont reproductibles sans autorisation, ce qui permet
                      à tout à chacun de demander la reproduction d’une clé.
                    </p>
                  }
                  textCenter
                />
              </Col>
              <Col
                sm
                className="ms-sm-3 mt-2 mt-md-0"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <PictoContent
                  image="../../assets/icons/icon-blindage.svg"
                  title="Les clés protégées ou clés brevetées :"
                  description={
                    <p>
                      Pour plus de sécurité, nous vous conseillons de choisir
                      une serrure avec une clef brevetée non reproductible. Pour
                      reproduire ces clefs, une carte de propriété vous est
                      demandée. Il est important de conserver votre carte de
                      propriété dans un endroit sécurisé.
                    </p>
                  }
                  textCenter
                />
              </Col>
            </Row>
          </div>
          <div className="text-center mt-3 mt-md-4">
            <DstButton
              btnClass="mx-4"
              value="Contactez-nous"
              clickFunction={() => navigate("/contact")}
            />
          </div>
        </section>

        <section className="container my-5">
          <div className="container-fluid">
            <h2 className="line-title">Types de clés</h2>
            <Row>
              <Col md="4" className="text-center align-self-center">
                <img
                  className="img-fluid mh-400 mb-3 mb-md-0"
                  src="../../assets/images/reproduction-clef.webp"
                  alt="reproduction clés"
                />
              </Col>
              <Col md className="align-self-center">
                <ul className="list-check">
                  <li>
                    Clés de portes anciennes : bénardes, à gorges, à gorges
                    forées ou à double panneton{" "}
                  </li>
                  <li>Clés de portes classiques : plates</li>
                  <li>Clés de portes sécurisées : à trou</li>
                  <li>
                    Clés de coffre, de valises, de cadenas ou d’antivol de
                    deux-roues
                  </li>
                  <li>Clés magnétiques</li>
                  <li>Clés de voiture : plates et électroniques</li>
                </ul>
              </Col>
            </Row>
          </div>
        </section>
        <section className="container my-5">
          <div className="container-fluid">
            <h2 className="line-title">Copie Badge</h2>
            <Row className="flex-column-reverse flex-md-row">
              <Col md className="align-self-center">
                <p>
                  La Serrurerie Guy Môquet, vous propose une solution rapide et
                  efficace, pour recréer vos badges d'immeubles.{" "}
                </p>
                <p>
                  Nous avons des machines capables de dupliquer automatiquement
                  et simplement 98% des badges actifs en Europe en un clic, et
                  ceci avec une garantie de fonctionnement fiable à 100%.{" "}
                </p>
                <p>
                  Pour dupliquer vos badges d'accès aux immeubles ou autres
                  badges, vous n'aurez plus besoin de faire un contrat de
                  propriété.{" "}
                </p>
                <p>
                  {" "}
                  Présentez-le simplement dans notre magasin au 17 rue de la
                  Jonquière 75017 Paris, muni d’une pièce d’identité en cours de
                  validité
                </p>
                <div className="d-sm-flex">
                  <div>
                    <DstButton
                      btnClass="me-0 me-md-3"
                      value="Contactez-nous"
                      clickFunction={() => navigate("/contact")}
                    />
                  </div>
                  <div>
                    <DstButton
                      btnClass="ms-0 ms-sm-3 mt-2 mt-sm-0"
                      value="Découvrir nos prestations "
                      variant="secondary"
                      clickFunction={() => navigate("/prestations")}
                    />
                  </div>
                </div>
              </Col>
              <Col md="4" className="text-center align-self-center">
                <img
                  className="img-fluid mh-400 mb-3 mb-md-0"
                  src="../../assets/images/badge.webp"
                  alt="reproduction badge"
                />
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>{" "}
    </div>
  );
};

export default ReproductionCle;
