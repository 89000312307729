const MapAdress = ({
  color = "#FB8C49",
  classes = "me-2",
}: {
  color?: string;
  classes?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={classes}
    width="28"
    height="28"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={color}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <circle cx="12" cy="11" r="3" />
    <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
  </svg>
);

export default MapAdress;
