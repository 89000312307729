import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import DstButton from "../../components/DstButton/DstButton";
import { Helmet } from "react-helmet";

const GrilleDefense = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Helmet>
        <title>Grilles de Défense - Serrurerie Guy Môquet Paris 17</title>
        <meta
          name="description"
          content="Découvrez nos grilles de défense innovantes, volets blindés et barres de sécurité amovibles robustes pour assurer la sécurité de votre maison ou appartement."
        />
      </Helmet>
      <section className="container my-5">
        <div className="container-fluid">
          <Row className="d-flex flex-column-reverse flex-md-row">
            <Col className="align-self-center text-center text-md-start">
              <div>
                <img
                  src="../../assets/images/grille-defense.webp"
                  alt="grille defense"
                  className="img-fluid"
                />
              </div>
              <DstButton
                btnClass="mt-3 d-md-none"
                value="Contactez-nous"
                clickFunction={() => navigate("/contact")}
              />
            </Col>
            <Col md="8">
              <h2 className="line-title">Grille de défense</h2>
              <p>
                Les <strong>Grilles de défense</strong> sont souvent un élément
                nécessaire dans une démarche de sécurisation d'un appartement ou
                d'une maison. C'est une solution de sécurité 100% efficace
                contre les cambriolages : pas une seule grille de défense
                fracturée !
              </p>
              <p>
                Nous posons pour vous des{" "}
                <strong>Grilles de défense innovantes</strong>, volets blindés
                et barres de sécurité amovibles extrêmement robustes,
                esthétiques et très faciles d’utilisation pour une sérénité
                totale face aux cambrioleurs.
              </p>
              <DstButton
                btnClass="mt-4 d-none d-md-block"
                value="Contactez-nous"
                clickFunction={() => navigate("/contact")}
              />
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};

export default GrilleDefense;
