import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const FormTemplate = () => {
  const form = useRef();
  const [disable, setDisable] = useState(0);

  const sendEmail = (e) => {
    e.preventDefault();
    setDisable(true);
    const formMess = document.querySelector(".formMessage");

    var x = document.forms["contact_form"]["email2"].value;

    if (x == "" || x == null) {
      emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE,
          process.env.REACT_APP_TEMPLATE,
          form.current,
          process.env.REACT_APP_ID
        )
        .then(
          (res) => {
            console.log(res.text);
            form.current.reset();
            document.querySelector(".form-content").style.display = "none";
            formMess.innerHTML =
              "<p class='success'>Bonjour, nous avons bien reçu votre message et ne manquerons pas de vous recontacter dans les plus brefs délais.</p>";

            // setTimeout(() => {
            //   formMess.innerHTML = "";
            // }, 40000);
          },
          (err) => {
            console.log(err.text);
            formMess.innerHTML =
              "<p class='error'>Une erreur s'est produite, veuillez réessayer</p>";

            setTimeout(() => {
              formMess.innerHTML = "";
            }, 2000);
          }
        );
    } else {
      form.current.reset();
      formMess.innerHTML = "<p class='success'>Message envoyé !</p>";
    }
  };

  return (
    <div className="form-container">
      <form
        ref={form}
        onSubmit={sendEmail}
        className="form-content"
        name="contact_form"
      >
        <label>Nom</label>
        <input type="text" name="name" required autoComplete="off" id="name" />
        <label>Email</label>
        <input type="email" name="email" required id="email" />
        <div className="d-none">
          <label>Email </label>
          <input id="email2" name="email2" type="text" />
        </div>
        <label>Téléphone</label>
        <input
          type="tel"
          name="tel"
          pattern="(0|\+33)[1-9]( *[0-9]{2}){4}"
          size="10"
          required
        />
        <label>Code postal</label>
        <input type="text" name="codepostal" pattern="[0-9]{5}" required />
        <label>Objet:</label>
        <select type="select" name="object" id="object">
          <option value="Aucun">--Veuillez choisir une option--</option>
          <option value="Dépannage">Dépannage et serrurerie</option>
          <option value="Porte blindé">Porte blindé</option>
          <option value="Serrure">Changement serrure</option>
          <option value="Clés">Reproduction clés - badge </option>
          <option value="Porte de Hall">Porte de hall</option>
          <option value="Digicode - Interphone">Digicode - Interphone</option>
          <option value="Porte de Box">Porte de box</option>
          <option value="Grille de défense">Grille de défense</option>
          <option value="Autre">Autre</option>
        </select>
        <label>Message</label>
        <textarea name="message" required id="mess" />
        <input
          type="submit"
          value="Envoyer"
          className="hover button"
          id="btn-contact"
          disabled={disable}
        />
      </form>
      <div className="formMessage"></div>
    </div>
  );
};

export default FormTemplate;
