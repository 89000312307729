import React from "react";
import { Col, Row } from "react-bootstrap";
import BanniereHeader from "../../components/banniereHeader/BanniereHeader";
import Mail from "../../components/DstIcons/Mail";
import MapAdress from "../../components/DstIcons/MapAdress";
import Tel from "../../components/DstIcons/Tel";
import FormTemplate from "../../components/FormTemplate/FormTemplate";
import { Helmet } from "react-helmet";

import "./Contact.scss";

const Contact = () => {
  const socialMediaIcons = [
    {
      link: "https://www.facebook.com/serrurerieguymoquet/",
      icon: "../../assets/icons/icon-facebook.svg",
      alt: "Facebook",
    },
    {
      link: "https://www.instagram.com/serrurerie_guymoquet/?igshid=YmMyMTA2M2Y%3D",
      icon: "../../assets/icons/icon-instagram.svg",
      alt: "Instagram",
    },
    {
      link: "https://www.linkedin.com/in/st%C3%A9phane-baroukh-780760209/",
      icon: "../../assets/icons/icon-linkedin.svg",
      alt: "Linkedin",
    },
    {
      link: "https://www.tiktok.com/@serrurerie_guy_moquet?_t=8ZgX0EMKP8o&_r=1",
      icon: "../../assets/icons/icon-tiktok.svg",
      alt: "TikTok",
    },
  ];

  return (
    <React.Fragment>
      <Helmet>
        <title>Contact - Serrurerie Guy Môquet - Paris 17</title>
        <meta
          name="description"
          content="Contactez-nous pour un devis gratuit. Vous avez des questions sur les serrures, les clés, les portes blindées, les coffres-forts, et plus encore ? Nous sommes là pour vous aider."
        />
        <meta
          property="og:title"
          content="Contact - Serrurerie Guy Môquet - Paris 17"
        />
        <meta
          property="og:description"
          content="Contactez-nous pour un devis gratuit. Vous avez des questions sur les serrures, les clés, les portes blindées, les coffres-forts, et plus encore ? Nous sommes là pour vous aider."
        />
        <meta
          property="og:image"
          content="/favicon/android-chrome-384x384.png"
        />
        <meta property="og:url" content="/favicon/android-chrome-384x384.png" />
      </Helmet>

      <section>
        <BanniereHeader
          title="Contact"
          subTitle={<h2 className="h4">Devis gratuit</h2>}
          backgroundImage="background-depannage.webp"
        />
      </section>

      <section className="container my-5">
        <div className="container-fluid">
          <h2 className="line-title">Demandez votre devis gratuitement</h2>
          <Row>
            <Col md="6">
              <FormTemplate />
            </Col>
            <Col className="">
              <p>
                Vous avez des questions sur les portes blindées, clés, serrures,
                cylindres, badges, coffres-forts, alarmes, grilles de défense,
                équipements de surveillance, .... ? Vous souhaitez dupliquer
                votre clé ou votre carte de propriété ?
              </p>
              <p>
                Envoyez-nous des questions, une demande de devis ou une photo de
                votre serrure ou de votre clé. Nous ferons de notre mieux pour
                vous répondre dans les plus brefs délais.
              </p>
              <h3 className="line-title-h3 mt-4">Contactez-nous</h3>
              <ul className="list-inline">
                <li className="menu-list">
                  <a href="mailto:serrurerie.guymoquet@gmail.com">
                    <Mail />
                    <span className="black-font">
                      serrurerie.guymoquet@gmail.com
                    </span>
                  </a>
                </li>
                <li className="menu-list">
                  <a href="tel:+33142289944">
                    <Tel />
                    <span className="black-font">01 42 28 99 44</span>
                  </a>
                </li>
                <li className="menu-list">
                  <a
                    href="https://www.google.com/maps/place/SERRURERIE+GUY+MOQUET+-+Picard+Serrures/@48.895387,2.3030792,14z/data=!4m18!1m12!4m11!1m3!2m2!1d2.3255827!2d48.8939196!1m6!1m2!1s0x47e66e54c85c1095:0x8a3e9f7df9b5b970!2sSERRURERIE+GUY+MOQUET+-+Picard+Serrures,+Rue+de+La+Jonqui%C3%A8re,+Paris!2m2!1d2.325612!2d48.893253!3m4!1s0x47e66e54c85c1095:0x8a3e9f7df9b5b970!8m2!3d48.893253!4d2.325612?hl=fr"
                    target="_blank"
                  >
                    <MapAdress />
                    <span className="black-font">
                      17 Rue de La Jonquière, 75017 Paris
                    </span>
                  </a>
                </li>
              </ul>
              <h3 className="line-title-h3 mt-4">Nos réseaux sociaux</h3>
              <div className="d-flex mt-3">
                {socialMediaIcons.map((icon, index) => (
                  <div className="me-2" key={index}>
                    <a href={icon.link} target="_blank">
                      <img
                        src={icon.icon}
                        alt={icon.alt}
                        className="icon-reseau"
                      />
                    </a>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Contact;
