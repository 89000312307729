import { useEffect, useLayoutEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.scss";
import DstFooter from "./components/Footer/Footer";
import DstNavbar from "./components/Navbar/Navbar";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Accueil from "./pages/Accueil/Accueil";
import Contact from "./pages/Contact/Contact";
import Depannage from "./pages/Depannage/Depannage";
import DigicodeInterphone from "./pages/DigicodeInterphone/DigicodeInterphone";
import Erreur404 from "./pages/Erreur404/Erreur404";
import GrilleDefense from "./pages/GrilleDefense/GrilleDefense";
import MentionLegales from "./pages/MentionLegales/MentionLegales";
import PorteBlinde from "./pages/PorteBlinde/PorteBlinde";
import PorteBox from "./pages/PorteBox/PorteBox";
import PorteHall from "./pages/PorteHall/PorteHall";
import PoseSerrure from "./pages/PoseSerrure/PoseSerrure";
import Prestations from "./pages/Prestations/Prestations";
import ReproductionCle from "./pages/ReproductionCle/ReproductionCle";
import Aos from "aos";
import "aos/dist/aos.css";

const App = () => {
  useEffect(() => {
    Aos.init({ once: true });
  });
  return (
    <BrowserRouter>
      <ScrollToTop />
      <DstNavbar />
      <Routes>
        <Route path="/" element={<Accueil />} />
        <Route path="/prestations" element={<Prestations />} />
        <Route path="/depannage-serrurerie" element={<Depannage />} />
        <Route path="/reproduction-cle" element={<ReproductionCle />} />
        <Route path="/pose-serrure" element={<PoseSerrure />} />
        <Route path="/porte-blinde" element={<PorteBlinde />} />
        <Route path="/porte-hall" element={<PorteHall />} />
        <Route path="/digicode-interphone" element={<DigicodeInterphone />} />
        <Route path="/porte-box" element={<PorteBox />} />
        <Route path="/grille-defense" element={<GrilleDefense />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/mentions-legales" element={<MentionLegales />} />
        <Route path="*" element={<Erreur404 />} />

        {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
        {/*
            Add routes here by using example on:
            <Route path="{YOUR_PATH}" element={YOUR_COMPONENT} />
          */}
      </Routes>
      <DstFooter />
    </BrowserRouter>
  );
};

export default App;
