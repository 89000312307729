import React from "react";
import { Link, useNavigate } from "react-router-dom";
import DstButton from "../../components/DstButton/DstButton";

const Erreur404 = () => {
  const navigate = useNavigate();

  return (
    <section className="container text-center my-5">
      <h1>Oups ! Page Introuvable (Erreur 404)</h1>
      <p>
        Nous sommes désolés, mais la page que vous cherchez semble introuvable.{" "}
      </p>
      <div>
        <DstButton
          value=" Retour a l'accueil"
          clickFunction={() => navigate("/")}
        />
      </div>
    </section>
  );
};

export default Erreur404;
