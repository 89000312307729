const ReviewGoogleMocking = [
  {
    name: "Marielle Baucher",
    photo:
      "https://lh3.googleusercontent.com/a-/ALV-UjXSts5g6DojugvTf9hievFK97FA33p1U1fqJnnyFRfngSibX0ixCw=s64-c-rp-mo-br100",
    time: new Date("March 15, 2024").getTime(),
    star: 5,
    description:
      "Je recommande vivement monsieur Baroukh pour la qualité de sa prestation et son expertise. Est intervenu pour un changement de cylindre qu il a parfaitement ajusté.",
  },
  {
    name: "Caroline",
    photo:
      "https://lh3.googleusercontent.com/a-/ALV-UjWq09bv4K_Nyq50lAws66dpsFYin5vh4F5aremgJG-sC7kflTRk=s64-c-rp-mo-br100",
    time: new Date("February 1, 2024").getTime(),
    star: 5,
    description:
      "Très bon serrurier, sérieux et honnête. M. Baroukh est intervenu chez nous pour ajuster la porte qui était capricieuse. Il s'avère finalement que celle-ci fonctionnait bien (le chaud/froid sans doute a dû faire gonfler le bois) et le serrurier ne nous a pas facturé. Il nous a également donné plein de bons conseils et posé les bonnes questions pour son devis. Vraiment super. Un grand merci à lui. Allez-y en confiance !",
  },
  {
    name: "Aurelie March",
    photo:
      "https://lh3.googleusercontent.com/a/ACg8ocL6cVX67f3gmczzutC00yIRbmkskoF5jnNmS-K7u_JR=s40-c-rp-mo-br100",
    time: new Date("November 1, 2023").getTime(),
    star: 5,
    description:
      "Serrurier sérieux, competent et efficace. Prix le moins cher auquel j ai eu à faire.",
  },
  {
    name: "Pauline de Molliens",
    photo:
      "https://lh3.googleusercontent.com/a/ACg8ocJCgpT3UO52Nb2lLpSJBVC2aFQp5T02r4EbFMajUsDaxQ=s40-c-rp-mo-br100",
    time: new Date("October 1, 2023").getTime(),
    star: 5,
    description:
      "Un serrurier honnête et patient quand la porte est capricieuse ! Je recommande ++++ (recommandé par la BSV Protection Paris 6e). D'autres me disaient qu'il fallait percer / démonter la serrure, alors que ça n'était pas nécessaire.",
  },
  {
    name: "Dominique Mairesse",
    photo:
      "https://lh3.googleusercontent.com/a/ACg8ocLXmMlzkLqHxdzJD1xJR6syHLUwd4fv345Tia8tKW6J=s40-c-rp-mo-br100",
    time: new Date("September 1, 2023").getTime(),
    star: 5,
    description:
      "Un grand merci !! Dépannage ultra rapide très professionnel prix très correct et en plus un Monsieur très sympathique.",
  },
  {
    name: "Aniss O",
    photo:
      "https://lh3.googleusercontent.com/a/AEdFTp5arKzUco3sCvDvpoie8oPsA59H3_JHJv98vlGf5zg=w120-h120-p-rp-mo-br100",
    time: 1673913600000,
    star: 5,
    description:
      "Personnel très agréable, et enfin un serrurier honnête !!! Très pro prestation au top ! Je recommande fortement.",
  },
  {
    name: "Louis Deconinck",
    photo:
      "https://lh3.googleusercontent.com/a/AEdFTp4PFbtYLKpvZIlUg-AfuSfuOnL5SzwJyPELh3LY=w120-h120-p-rp-mo-br100",

    star: 5,
    time: 1671840000000,
    description:
      "Serrurier Honnête et sympathique, si vous êtes dans une situation compliqué et que vous ne savez pas qui contacter, je vous le conseille vivement.",
  },
  {
    name: "Théo Fourmillier",
    photo:
      "https://lh3.googleusercontent.com/a-/AD5-WCluTbGUZ2qjEPTs7-Q6FTfLXEsUgbPJDMTkrPUg=w120-h120-p-rp-mo-br100",
    time: 1671840000000,
    star: 5,
    description:
      "Excellent serrurier, très professionnel et rapide avec des tarifs plus que respectable. Fortement recommandable.",
  },
  {
    name: "Colette Colette",
    photo:
      "https://lh3.googleusercontent.com/a/AEdFTp6hU4CFOPt_NGM_6M6YInLoWTJmRsLjhyBrqMly=w120-h120-p-rp-mo-br100",
    time: 1642982400000,
    star: 5,
    description:
      "Ce serrurier a installé chez moi un bloc-blindage pour la porte d'entrée de mon appartement. Artisan hyper professionnel et très à l'écoute. Il m'a expliqué avec beaucoup de pédagogie les caractéristiques du produit et les étapes d'installation. Travail rapide, efficace et soigné. Qui plus est, cet artisan traite ses jeunes assistants avec beaucoup de respect. Je recommande à 100% ! Un grand merci à lui.",
  },
  {
    name: "Pierre Le Chaponnier",
    photo:
      "https://lh3.googleusercontent.com/a-/AD5-WCmXG6pNa5F4daJlvXSMqKs0vtxG_9kTTU2QhvgxHg=w120-h120-p-rp-mo-br100",
    star: 5,
    time: 1642982400000,
    description:
      "Est intervenu très rapidement chez nous en plein mois d’août pour une clé cassée dans la serrure de notre appartement, pour un tarif tout à fait honnête ! Merci pour votre professionnalisme et votre réactivité",
  },
  {
    name: "Shute",
    photo:
      "https://lh3.googleusercontent.com/a-/AD5-WCnTgnScfz9CS6HRQHqKVvaHehxwk2dd0cyid9ad=w120-h120-p-rp-mo-br100",
    star: 5,
    time: 1674735012000,
    description:
      "Efficace, très sympathique et pédagogue ! Prix dans la normale et service parfait.",
  },
  {
    name: "Chantal Bastelica",
    photo:
      "https://lh3.googleusercontent.com/a/AEdFTp6_9PHhDU92klyLmGuOfZJV1bY8jugA2zIrtRDb=w120-h120-p-rp-mo-br100",
    star: 5,
    time: 1674000000000,
    description:
      "Très bon artisan, sympathique, serviable et efficace. M. B. m'a tout d'abord proposé une solution à court terme pour me dépanner (c’était la veille des vacances d’été) tout en m’expliquant, de façon très claire et sans chercher la formule la plus coûteuse, ce que je pouvais envisager par la suite. Je suis très satisfaite de l'installation qu'il m'a faite et sais que je peux compter sur lui en cas de problème. Je recommande vivement son entreprise.",
  },
];

export default ReviewGoogleMocking;
