import React, { Component } from "react";

import ReviewGoogleMocking from "./ReviewGoogle.mock";
import { Rating } from "@mui/material";
import "./ReviewGoogle.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactReadMoreReadLess from "react-read-more-read-less";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
const YEARS_IN_MILLISECONDS = 31556952000;
const MONTH_IN_MILLISECONDS = 30 * 7 * 24 * 60 * 60 * 1000;
const WEEK_IN_MILLISECONDS = 7 * 24 * 60 * 60 * 1000;
const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
const HOURS_IN_MILLISECONDS = 60 * 60 * 1000;
const MINUTES_IN_MILLISECONDS = 60 * 1000;

const ReviewGoogle = () => {
  return (
    <Carousel
      swipeable={true}
      draggable={true}
      showDots={true}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={3000}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-review"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
      {ReviewGoogleMocking.map((avis, index) => {
        let now = Date.now();
        let diff = now - avis.time; // milliseconds
        if (diff > YEARS_IN_MILLISECONDS) {
          let displayTmp = Math.floor(diff / YEARS_IN_MILLISECONDS);
          return (
            <div key={index} className="h-100 card-style rounded text-center">
              <img
                src={avis.photo}
                alt={avis.name}
                height="40"
                className="rounded-circle mb-1"
              />
              <div className="fw-bold"> {avis.name}</div>
              <Rating
                name="read-only"
                value={avis.star}
                readOnly
                className="mt-2"
              />
              <div className="gray-font xs-regular">
                il y a {displayTmp} an{displayTmp > 1 ? "s" : ""}
              </div>
              <div className="mt-2">
                <ReactReadMoreReadLess
                  charLimit={100}
                  readMoreText={"Lire la suite"}
                  readLessText={"Lire moins"}
                >
                  {avis.description}
                </ReactReadMoreReadLess>
              </div>
            </div>
          );
        }
        if (diff > MONTH_IN_MILLISECONDS) {
          let displayTmp = Math.floor(diff / MONTH_IN_MILLISECONDS);
          return (
            <div key={index} className="h-100 card-style rounded text-center">
              <img
                src={avis.photo}
                alt={avis.name}
                height="40"
                className="rounded-circle mb-1"
              />
              <div className="fw-bold"> {avis.name}</div>
              <Rating
                name="read-only"
                value={avis.star}
                readOnly
                className="mt-2"
              />
              <div className="gray-font xs-regular">
                il y a {displayTmp} mois
              </div>
              <div className="mt-2">
                <ReactReadMoreReadLess
                  charLimit={100}
                  readMoreText={"Lire la suite"}
                  readLessText={"Lire moins"}
                >
                  {avis.description}
                </ReactReadMoreReadLess>
              </div>
            </div>
          );
        }
        if (diff > WEEK_IN_MILLISECONDS) {
          let displayTmp = Math.floor(diff / WEEK_IN_MILLISECONDS);
          return (
            <div key={index} className="h-100 card-style rounded text-center">
              <img
                src={avis.photo}
                alt={avis.name}
                height="40"
                className="rounded-circle mb-1"
              />
              <div className="fw-bold"> {avis.name}</div>
              <Rating
                name="read-only"
                value={avis.star}
                readOnly
                className="mt-2"
              />
              <div className="gray-font xs-regular">
                il y a {displayTmp} semaine{displayTmp > 1 ? "s" : ""}
              </div>
              <div className="mt-2">
                <ReactReadMoreReadLess
                  charLimit={100}
                  readMoreText={"Lire la suite"}
                  readLessText={"Lire moins"}
                >
                  {avis.description}
                </ReactReadMoreReadLess>
              </div>
            </div>
          );
        }
        if (diff > DAY_IN_MILLISECONDS) {
          let displayTmp = Math.floor(diff / DAY_IN_MILLISECONDS);
          return (
            <div key={index} className="h-100 card-style rounded text-center">
              <img
                src={avis.photo}
                alt={avis.name}
                height="40"
                className="rounded-circle mb-1"
              />
              <div className="fw-bold"> {avis.name}</div>
              <Rating
                name="read-only"
                value={avis.star}
                readOnly
                className="mt-2"
              />
              <div className="gray-font xs-regular">
                il y a {displayTmp} jour{displayTmp > 1 ? "s" : ""}
              </div>
              <div className="mt-2">
                <ReactReadMoreReadLess
                  charLimit={100}
                  readMoreText={"Lire la suite"}
                  readLessText={"Lire moins"}
                >
                  {avis.description}
                </ReactReadMoreReadLess>
              </div>
            </div>
          );
        }
        if (diff > HOURS_IN_MILLISECONDS) {
          let displayTmp = Math.floor(diff / HOURS_IN_MILLISECONDS);
          return (
            <div key={index} className="h-100 card-style rounded text-center">
              <img
                src={avis.photo}
                alt={avis.name}
                height="40"
                className="rounded-circle mb-1"
              />
              <div className="fw-bold"> {avis.name}</div>
              <Rating
                name="read-only"
                value={avis.star}
                readOnly
                className="mt-2"
              />
              <div className="gray-font xs-regular">
                il y a {displayTmp} heure{displayTmp > 1 ? "s" : ""}
              </div>
              <div className="mt-2">
                <ReactReadMoreReadLess
                  charLimit={100}
                  readMoreText={"Lire la suite"}
                  readLessText={"Lire moins"}
                >
                  {avis.description}
                </ReactReadMoreReadLess>
              </div>
            </div>
          );
        }
        if (diff > MINUTES_IN_MILLISECONDS) {
          let displayTmp = Math.floor(diff / MINUTES_IN_MILLISECONDS);
          return (
            <div key={index} className="h-100 card-style rounded text-center">
              <img
                src={avis.photo}
                alt={avis.name}
                height="40"
                className="rounded-circle mb-1"
              />
              <div className="fw-bold"> {avis.name}</div>
              <Rating
                name="read-only"
                value={avis.star}
                readOnly
                className="mt-2"
              />
              <div className="gray-font xs-regular">
                il y a {displayTmp} minute{displayTmp > 1 ? "s" : ""}
              </div>
              <div className="mt-2">
                <ReactReadMoreReadLess
                  charLimit={100}
                  readMoreText={"Lire la suite"}
                  readLessText={"Lire moins"}
                >
                  {avis.description}
                </ReactReadMoreReadLess>
              </div>
            </div>
          );
        } else
          return (
            <div key={index} className="h-100 card-style rounded text-center">
              <img
                src={avis.photo}
                alt={avis.name}
                height="40"
                className="rounded-circle mb-1"
              />
              <div className="fw-bold"> {avis.name}</div>
              <Rating
                name="read-only"
                value={avis.star}
                readOnly
                className="mt-2"
              />
              <div>Aucune date</div>
              <div className="mt-2">
                <ReactReadMoreReadLess
                  charLimit={100}
                  readMoreText={"Lire la suite"}
                  readLessText={"Lire moins"}
                >
                  {avis.description}
                </ReactReadMoreReadLess>
              </div>
            </div>
          );
      })}
    </Carousel>
  );
};

export default ReviewGoogle;
