import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

import "./DstButton.scss";

const DstButton = ({
  backgroundColor,
  btnClass = "s-bold",
  btnImage,
  btnSize = "auto",
  clickFunction,
  color,
  disabled,
  link,
  type = "button",
  value = "Click here",
  variant = "primary",
}: {
  backgroundColor?: string;
  btnClass?: string;
  btnImage?: JSX.Element;
  btnSize?: string;
  clickFunction?: Function;
  color?: string;
  disabled?: boolean;
  link?: string;
  type?: "submit" | "button" | "reset" | undefined;
  value?: string;
  variant?: string;
}) =>
  link ? (
    <Link
      className={`s-bold ${btnClass}`}
      style={{
        backgroundColor: backgroundColor,
        color: color,
        width: btnSize,
      }}
      to={link}
    >
      {value}
    </Link>
  ) : (
    <Button
      variant={variant}
      className={`s-bold ${btnClass}`}
      style={{
        backgroundColor: backgroundColor,
        color: color,
        width: btnSize,
      }}
      type={type}
      onClick={() => (clickFunction ? clickFunction() : null)}
      disabled={disabled}
    >
      <>
        {btnImage}
        {value}
      </>
    </Button>
  );

export default DstButton;
