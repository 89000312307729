import { NavLink } from "react-router-dom";
import DstButton from "../DstButton/DstButton";

import "./BanniereHeader.scss";

const BanniereHeader = ({
  backgroundImage,
  btnClass = "",
  subTitle,
  color,
  title,
  textButton = "01 42 28 99 44",
  link,
  clickFunction,
}: {
  backgroundImage?: string;
  btnClass?: string;
  title?: string;
  subTitle?: JSX.Element;
  color?: string;
  textButton?: string;
  link?: string;
  clickFunction?: Function;
}) => (
  <div
    className={`ban-header ${backgroundImage}`}
    style={{
      backgroundImage: `url("../../assets/header/${backgroundImage}")`,
    }}
  >
    <div className="container text-center py-3 py-md-4">
      <h1>{title}</h1>
      {subTitle}
      {link ? (
        <NavLink to={link}>
          <DstButton value={textButton} variant="primary" />
        </NavLink>
      ) : (
        <a href="tel:+33142289944">
          <DstButton value={textButton} variant="primary" />
        </a>
      )}
    </div>
  </div>
);

export default BanniereHeader;
