import React from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import BanniereHeader from "../../components/banniereHeader/BanniereHeader";
import DstButton from "../../components/DstButton/DstButton";
import PictoContent from "../../components/Pictocontent/PictoContent";
import { Helmet } from "react-helmet";
import "./Depannage.scss";

const Depannage = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Dépannage Serrurerie 24h/24 - Serrurerie Guy Môquet Paris 17
        </title>
        <meta
          name="description"
          content="Problème de serrure à Paris ? Serrurerie Guy Môquet propose un dépannage rapide et efficace des serrures à Paris 17 et en Île-de-France."
        />
        <meta
          name="keywords"
          content="dépannage serrures Paris, réparation serrures Paris, serrurier dépannage Paris, serrurier urgent Paris"
        />
      </Helmet>
      <section>
        <BanniereHeader
          title="Urgence & Dépannage"
          subTitle={<h2 className="h4">Dépannage Serrurerie 24h/24</h2>}
          backgroundImage="background-depannage.webp"
        />
      </section>
      <section className="container my-5">
        <div className="container-fluid">
          <Row>
            <Col md="6">
              <p>
                Besoin d'un service de{" "}
                <span className="keyword fw-bold">dépannage serrurerie</span>{" "}
                efficace pour une{" "}
                <span className="keyword fw-bold">porte claquée</span> ou
                bloquée, ou une{" "}
                <span className="keyword fw-bold">réparation de serrure</span>{" "}
                rapide ?
              </p>
              <p>
                Nos techniciens apportent des solutions rapides et efficaces
                grâce à notre{" "}
                <span className="keyword fw-bold">expertise serrurier</span>.
              </p>
              <p>
                Si vous avez besoin d'aide en matière de{" "}
                <span className="keyword fw-bold">serrurerie d'urgence</span>,
                n'hésitez pas à nous contacter. Nous vous fournirons des
                conseils sur mesure pour votre{" "}
                <span className="keyword fw-bold">urgence en serrurerie</span>.
              </p>
              <p className="fw-bold">
                <span className="keyword">Dépannage serrurerie</span> d’urgence
                et ouverture de porte
              </p>
              <p>
                Votre porte a claqué, vous avez perdu vos clés, votre clé s'est
                cassée dans la serrure, vous avez subi un cambriolage ou votre
                serrure est défectueuse ?
              </p>
              <p>
                Les artisans de la{" "}
                <span className="keyword">Serrurerie Guy Môquet</span> sont
                disponibles pour vous aider sur Paris et toute l’Île de France{" "}
                <span className="keyword fw-bold">24 heures sur 24</span> en cas
                d'urgence serrurerie.
              </p>
              <p>
                Nous travaillons aussi bien avec des particuliers que des
                professionnels et pratiquons une grille tarifaire{" "}
                <span className="keyword">transparente</span> et{" "}
                <span className="keyword">raisonnable</span> pour tous vos
                besoins en serrurerie.
              </p>
            </Col>
            <Col className="align-self-center text-center">
              <img
                src="../../assets/images/depannage.webp"
                alt="boutique"
                className="img-fluid"
              />
            </Col>
          </Row>
        </div>
      </section>
      <section className="container my-5">
        <div className="container-fluid">
          <Row>
            <Col data-aos="fade-left" data-aos-delay="0">
              {" "}
              <PictoContent
                image="../../assets/icons/icon-porte.svg"
                title="Ouverture de porte"
                description={
                  <p>
                    Porte claquée, serrure forcée ou clés égarées ? Faites appel
                    à notre serrurier qualifié pour l’ouverture de votre porte.
                  </p>
                }
                textCenter
              />
            </Col>
            <Col data-aos="fade-left" data-aos-delay="500">
              {" "}
              <PictoContent
                image="../../assets/icons/icon-rapide.svg"
                title="Intervention rapide"
                description={
                  <p>
                    En cas d'urgence, nous intervenons rapidement (en général
                    dans l'heure) pour tout problème de serrure ou de porte.
                  </p>
                }
                textCenter
              />
            </Col>
            <Col data-aos="fade-left" data-aos-delay="1000" dura>
              {" "}
              <PictoContent
                image="../../assets/icons/icon-securise.svg"
                title="Domicile sécurisé"
                description={
                  <p>
                    Votre sécurité est notre priorité. Nous faisons en sorte que
                    votre domicile soit sécurisé pour votre tranquillité.
                  </p>
                }
                textCenter
              />
            </Col>
          </Row>
        </div>
        <div className="text-center">
          <DstButton
            btnClass="text-center"
            value="Contactez-nous"
            clickFunction={() => navigate("/contact")}
          />
        </div>
      </section>
      <section className="container my-5">
        <div className="container-fluid">
          <h2 className="line-title">Intervention tout type de dépannage</h2>
          <Row>
            <Col md="4" className="text-center align-self-center">
              <img
                className="img-fluid mh-400 mb-3 mb-md-0"
                src="../../assets/images/tout-depannage.webp"
                alt="tout type de dépannage"
              />
            </Col>
            <Col md className="align-self-center">
              <ul className="list-check">
                <li>
                  <strong>Dépannage d'urgence</strong> tout type de travaux en
                  serrurerie
                </li>
                <li>
                  <strong>Ouverture de porte</strong>
                </li>
                <li>
                  <strong>Changement de serrures</strong>
                </li>
                <li>
                  <strong>Mise aux normes assurances A2p</strong>
                </li>
                <li>
                  <strong>Dégrippage, installation ou remplacement</strong> de
                  serrures, cylindres ou barillets
                </li>
                <li>
                  <strong>Sécurisation après cambriolage</strong>
                </li>
                <li>
                  <strong>Montant de porte tordu</strong>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
        <div className="text-center mt-3">
          <DstButton
            btnClass="mx-4"
            value="Contactez-nous"
            clickFunction={() => navigate("/contact")}
          />
          <DstButton
            btnClass="mx-4 mt-2 mt-sm-0"
            value="Découvrir nos prestations "
            variant="secondary"
            clickFunction={() => navigate("/prestations")}
          />
        </div>
      </section>
    </React.Fragment>
  );
};

export default Depannage;
