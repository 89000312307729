import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import BanniereHeader from "../../components/banniereHeader/BanniereHeader";
import CardLink from "../../components/CardLink/CardLink";
import DstButton from "../../components/DstButton/DstButton";
import { Helmet } from "react-helmet";
import "./Prestations.scss";

const Prestations = () => {
  const cardData = [
    {
      link: "depannage-serrurerie",
      image: "ouverture-porte.webp",
      title: "Dépannage et serrurerie",
      description:
        "Besoin d'un dépannage en serrurerie ? Notre équipe est disponible 24h/24 pour résoudre tous vos problèmes de serrure et de porte.",
      delay: 0,
    },
    {
      link: "porte-blinde",
      image: "porte-blinde.webp",
      title: "Porte blindée",
      description:
        "Découvrez nos portes blindées de haute sécurité pour protéger votre domicile. Installation par nos experts certifiés.",
      delay: 0,
    },
    {
      link: "pose-serrure",
      image: "changement-serrure.webp",
      title: "Changement de serrure",
      description:
        "Besoin de changer votre serrure ? Nos serruriers qualifiés vous proposent un service rapide et efficace pour augmenter la sécurité de votre domicile.",
      delay: 0,
    },
    {
      link: "reproduction-cle",
      image: "reproduction-cles.webp",
      title: "Reproduction de clés",
      description:
        "Besoin de reproduire des clés ? Nous offrons un service de reproduction de clés de haute qualité pour tous types de serrures.",
      delay: 0,
    },
    {
      link: "porte-hall",
      image: "porte-hall.webp",
      title: "Porte de hall",
      description:
        "Découvrez nos portes de hall en aluminium ou en acier pour bâtiments résidentiels et commerciaux. Installation sur mesure.",
      delay: 0,
    },
    {
      link: "digicode-interphone",
      image: "digicode-interphone.webp",
      title: "Digicode - Interphone",
      description:
        "Besoin de systèmes de contrôle d'accès ? Nous proposons des solutions de digicodes et d'interphones pour renforcer la sécurité.",
      delay: 0,
    },
    {
      link: "porte-box",
      image: "porte-box.webp",
      title: "Porte de box",
      description:
        "Découvrez nos portes de box pour sécuriser vos biens. Installation professionnelle pour une protection maximale.",
      delay: 0,
    },
    {
      link: "grille-defense",
      image: "grille-defense.webp",
      title: "Grille de défense",
      description:
        "Besoin de renforcer la sécurité de votre domicile ? Découvrez nos grilles de défense robustes et esthétiques.",
      delay: 0,
    },
  ];

  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Helmet>
        <title>Nos prestations - Serrurerie Guy Môquet</title>
        <meta
          name="description"
          content="Découvrez nos services de serrurerie et de sécurité, allant du dépannage en serrurerie à l'installation de portes blindées et de systèmes de sécurité avancés."
        />
      </Helmet>
      <section>
        <BanniereHeader
          title="Nos prestations"
          subTitle={<h2 className="h4">Dépannage Serrurerie 24h/24</h2>}
          backgroundImage="background-depannage.webp"
        />
      </section>
      <section className="container my-5">
        <div className="container-fluid">
          <h2 className="line-title">Toutes nos prestations</h2>
          <Row className="text-center h-100">
            {cardData.map((card, index) => (
              <Col
                key={index}
                xs="6"
                lg="3"
                className="mb-2 px-1 xxs-mobile-100"
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay={card.delay}
                data-aos-offset="0"
                data-aos-duration={200 * (index + 1)}
              >
                <CardLink
                  link={card.link}
                  image={card.image}
                  title={card.title}
                  description={card.description}
                />
              </Col>
            ))}
          </Row>
          <div className="text-center mt-3">
            <DstButton
              value="Contactez-nous"
              clickFunction={() => navigate("/contact")}
            />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Prestations;
