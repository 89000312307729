const tiktok = ({
  color = "#FB8C49",
  classes = "me-2",
}: {
  color?: string;
  classes?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={classes}
    width="28"
    height="28"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={color}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M9 12a4 4 0 1 0 4 4v-12a5 5 0 0 0 5 5" />
  </svg>
);

export default tiktok;
