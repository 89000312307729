import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import DstButton from "../../components/DstButton/DstButton";
import { Helmet } from "react-helmet";

const PorteBox = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Helmet>
        <title>Porte de Box - Serrurerie Guy Môquet Paris</title>
        <meta
          name="description"
          content="Découvrez nos services d'installation de portes de box de haute qualité pour assurer la sécurité de vos biens. Contactez-nous pour une installation professionnelle dès maintenant."
        />
      </Helmet>
      <section className="container my-5">
        <div className="container-fluid">
          <Row className="d-flex flex-column-reverse flex-md-row">
            <Col className="align-self-center text-center text-md-start">
              <div>
                <img
                  src="../../assets/images/porte-box.webp"
                  alt="porte box"
                  className="img-fluid"
                />
              </div>
              <DstButton
                btnClass="mt-3 d-md-none"
                value="Contactez-nous"
                clickFunction={() => navigate("/contact")}
              />
            </Col>
            <Col md="8">
              <h2 className="line-title">Porte de box</h2>
              <p>
                La pose d'une <strong>porte de garage</strong> ou de{" "}
                <strong>box</strong> est une étape importante pour assurer la
                sécurité de votre véhicule ou de vos biens. Chez notre
                entreprise de <strong>serrurerie</strong>, nous sommes
                spécialisés dans la pose de <strong>portes de garage</strong> et
                de <strong>box</strong>, et nous sommes prêts à vous accompagner
                dans ce processus.
              </p>
              <p>
                Nous offrons une large sélection de{" "}
                <strong>portes de garage</strong> et de <strong>box</strong>,
                qui sont toutes fabriquées avec des matériaux de haute qualité
                pour garantir la sécurité et la durabilité. Nos{" "}
                <strong>portes</strong> sont disponibles dans différentes
                tailles, couleurs et styles pour s'adapter à vos besoins et à
                l'esthétique de votre propriété.
              </p>
              <p>
                Notre équipe de professionnels qualifiés est en mesure
                d'installer votre <strong>porte de garage</strong> ou de{" "}
                <strong>box</strong> rapidement et efficacement, tout en
                garantissant la qualité et la sécurité de l'installation. Nous
                prenons également en compte les particularités de votre{" "}
                <strong>garage</strong> ou <strong>box</strong> pour garantir
                une installation sur mesure et adaptée à votre environnement.
              </p>
              <p>
                N'hésitez pas à nous contacter pour plus d'informations sur nos
                services de pose de <strong>porte de garage</strong> ou de{" "}
                <strong>box</strong>. Nous serons heureux de vous aider à
                trouver la meilleure solution pour répondre à vos besoins en
                matière de sécurité et de confort.
              </p>

              <DstButton
                btnClass="mt-4 d-none d-md-block"
                value="Contactez-nous"
                clickFunction={() => navigate("/contact")}
              />
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};

export default PorteBox;
