import React from "react";
import "./PictoContent.scss";

const PictoContent = ({
  image,
  btnClass = "",
  description,
  title,
  alt,
  clickFunction,
  textCenter,
  dFlex,
  dSmFlex,
  souligne,
  centerVerticaly,
}: {
  image?: string;
  btnClass?: string;
  description?: JSX.Element;
  title?: string;
  alt?: string;
  clickFunction?: Function;
  textCenter?: boolean;
  dFlex?: boolean;
  dSmFlex?: boolean;
  souligne?: boolean;
  centerVerticaly?: boolean;
}) => {
  return (
    <div
      className={`picto-content justify-content-center
      ${textCenter ? "text-center" : ""} 
      ${dFlex ? "d-flex" : ""} ${dSmFlex ? "d-sm-flex" : ""}`}
    >
      <div className="picto-img">
        <img src={image} alt={alt ? alt : title} />
      </div>
      <div
        className={`${dSmFlex ? "ms-sm-2 mt-2 mt-sm-0" : "mt-2"} ${
          centerVerticaly ? "align-self-center" : ""
        }`}
      >
        {title ? (
          <h3 className="picto-title keyword font-weight-bold">{title}</h3>
        ) : (
          ""
        )}
        {description ? (
          <div className="picto-description">{description}</div>
        ) : (
          ""
        )}
      </div>
      {souligne ? <hr className="souligne-orange mx-auto" /> : ""}
    </div>
  );
};

export default PictoContent;
